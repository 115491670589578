.details {
  position: fixed;
  border-radius: 3px;
  background-color: white;
  width: 75vw;
  height: 90vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  padding: 1.6rem 2.4rem;
  transition: all 0.5s;
  gap: 0.8rem;

  @media only screen and (max-width: 900px) {
    width: 82.5vw;
  }

  @media only screen and (max-width: 600px) {
    width: 90vw;
  }

  &--dark {
    background-color: $color-gray-7;
    color: white;
  }

  &__buttonbar {
    display: flex;
    justify-content: flex-end;
    gap: 0.4rem;
  }

  &__map-button {
    padding: 0.8rem 2rem;
    font-size: $font-medium;
    border-radius: 1000px;
    box-shadow: $shadow-dark;
    // position: absolute;
    display: inline-block;
    right: 1.6rem;
    bottom: 1.6rem;
    cursor: pointer;
    z-index: 100;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      transition: all 0.3s;
      transform: translateY(-0.8rem);
    }

    &:active {
      transition: all 0.3s;
      transform: translateY(-0.4rem);
    }

    &--googlemaps {
      background-color: green;
      color: #ddffdd;
    }

    &--openstreetmap {
      background-color: gray;
      color: #cccccc;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 2.4rem;
  }

  &__title {
    flex: 1;
    font-family: $font-gilroy;
    letter-spacing: -0.1rem;
    color: black;
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;

    &--dark {
      color: white;
    }

    & p {
      font-weight: 600;
      font-size: 1.6rem;
    }

    &-frame {
      padding: 0.4rem 0.6rem;
      display: inline-flex;
      align-items: center;
      gap: 1.2rem;
      border-radius: 9999px;
      background-color: #cccccc;
    }

    // &-frame--dark {
    //   background-color: $color-gray-7;
    // }

    &-button {
      cursor: pointer;
      padding: 0.5rem 1rem;
      display: inline-block;
      border-radius: 9999px;
    }

    &-button--details {
      background-color: #eeeeee;
    }
  }

  &__save-icon {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;

    &:hover {
      transition: all 0.3s;
      transform: scale(1.2);
    }
  }

  &__exit {
    // font-weight: 900;
    // font-size: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;

    &:hover {
      transition: all 0.3s;
      transform: scale(1.2);
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 0.8fr 3.2fr;
    grid-template-rows: 1.2fr 1.8fr;
    flex: 1;
    column-gap: 2rem;
    min-width: 0;
    min-height: 0;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1.8fr 1.2fr;
      grid-template-rows: 1fr 3fr;
    }

    & img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 3px;
      transition: transform 0.3s;

      @media only screen and (max-width: 600px) {
        max-width: 100%;
        max-height: 100%;
      }

      &:hover {
        transform: scale(1.5);
      }
    }
  }

  &__flag {
    align-self: center;

    & img {
      border: 1px solid $color-gray-1;
    }
  }

  &__arms {
    order: 3;
    align-self: center;

    @media only screen and (max-width: 600px) {
      order: 2;
    }
  }

  &__list {
    order: 2;
    grid-row: span 2;
    display: grid;
    padding: 1.8rem 0;

    @media only screen and (max-width: 600px) {
      order: 3;
      grid-column: span 2;
    }

    &-item {
      display: flex;
      font-size: $font-medium;
      align-items: center;
      justify-content: space-between;

      &:nth-child(even) {
        background-color: $color-gray-1;
      }

      &--dark {
        &:nth-child(even) {
          background-color: $color-gray-8;
        }
      }

      & p {
        font-weight: 700;
      }

      & span {
        font-weight: 400;
      }
    }
  }
}

.map {
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
