section.container {
  width: 90%;
  position: relative;
  margin: 0 auto;
}

header.hero {
  position: relative;
  width: 100%;
  height: 50vh;
}

nav.search {
  position: absolute;
  width: 100%;
  height: 6rem;
  top: 50vh;
  transform: translateY(-50%);
  z-index: 15;
}

article.countries {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  row-gap: 1.2rem;
  column-gap: 1.8rem;

  @media only screen and (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.country-tools {
  position: relative;
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  width: 100%;
  row-gap: 1.2rem;
  column-gap: 1.8rem;

  // @media only screen and (max-width: 1000px) {
  //   grid-template-columns: repeat(12, 1fr);
  // }

  // // @media only screen and (max-width: 600px) {
  // //   grid-template-columns: repeat(9, 1fr);
  // // }

  // @media only screen and (max-width: 500px) {
  //   grid-template-columns: repeat(6, 1fr);
  // }
}

footer.footer {
  width: 100%;
  height: auto;
  padding: 1rem 0;
  margin-top: 3rem;
}

.cflex {
  display: flex;
  flex-direction: column;
  margin-top: 7rem;
  gap: 3rem;

  &__row {
    display: flex;
  }

  &__title {
    font-family: $font-gilroy;
    font-size: $font-big;
    color: $color-gray-7;

    &--dark {
      color: $color-gray-2;
    }
  }

  &__show-all,
  &__show-random {
    display: inline-block;
    background-color: $color-gray-2;
    color: $color-gray-8;
    padding: 0.4rem 0.8rem;
    margin-left: 1.2rem;
    font-size: $font-small;
    border-radius: 1000px;
    cursor: pointer;
    text-decoration: none;

    &--dark {
      background-color: $color-gray-7;
      color: $color-gray-1;
    }
  }

  &__bad-request {
    &--dark {
      color: $color-gray-2;
    }
  }

  &__not-found {
    &--dark {
      color: $color-gray-2;
    }
  }
}

.focus {
  z-index: 3;
  transition: all 0.3s;
}

.error-message {
  font-size: $font-medium;
}

.focus,
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 10;
  transition: all 0.5s;
}
