.search {
  border-radius: 1000px;
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
  display: flex;

  &--dark {
    background-color: $color-gray-8;
  }

  &__input {
    flex: 1;
    height: 100%;
    border: none;
    font-size: $font-huge;
    // font-family: inherit;
    font-family: $font-gilroy;
    font-weight: 700;
    padding: 0 1.6rem;
    background-color: inherit;
    color: $color-gray-9;

    &--dark {
      color: $color-gray-0;
    }

    &:focus {
      outline: none;
    }

    &:placeholder-shown {
      font-size: $font-big;

      @media (max-width: 600px) {
        font-size: $font-medium;
      }
    }
  }

  &__button {
    width: 7rem;
    height: 100%;
    border: none;
    background-color: inherit;
    cursor: pointer;
    position: relative;
  }

  &__icon {
    color: $color-gray-8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &--dark {
      color: $color-gray-1;
    }

    &:hover {
      transition: transform 0.3s;
      transform: scale(1.3) translate(-50%, -50%);
    }
  }
}
