:root {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
}

* {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

@media only screen and (width <= 900px) {
  html {
    font-size: 50%;
  }
}

@media only screen and (width <= 600px) {
  html {
    font-size: 45%;
  }
}

body {
  background-color: #f8f9fa;
  font-family: Open Sans, sans-serif;
}

.body--dark {
  background-color: #212529;
}

section.container {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

header.hero {
  width: 100%;
  height: 50vh;
  position: relative;
}

nav.search {
  z-index: 15;
  width: 100%;
  height: 6rem;
  position: absolute;
  top: 50vh;
  transform: translateY(-50%);
}

article.countries {
  grid-template-columns: repeat(4, 1fr);
  gap: 1.2rem 1.8rem;
  width: 100%;
  display: grid;
  position: relative;
}

@media only screen and (width <= 1500px) {
  article.countries {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (width <= 900px) {
  article.countries {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (width <= 600px) {
  article.countries {
    grid-template-columns: 1fr;
  }
}

.country-tools {
  grid-template-columns: repeat(18, 1fr);
  gap: 1.2rem 1.8rem;
  width: 100%;
  display: grid;
  position: relative;
}

footer.footer {
  width: 100%;
  height: auto;
  margin-top: 3rem;
  padding: 1rem 0;
}

.cflex {
  flex-direction: column;
  gap: 3rem;
  margin-top: 7rem;
  display: flex;
}

.cflex__row {
  display: flex;
}

.cflex__title {
  color: #495057;
  font-family: Gilroy, Plus Jakarta Sans, Open Sans, sans-serif;
  font-size: 2rem;
}

.cflex__title--dark {
  color: #e9ecef;
}

.cflex__show-all, .cflex__show-random {
  color: #343a40;
  cursor: pointer;
  background-color: #e9ecef;
  border-radius: 1000px;
  margin-left: 1.2rem;
  padding: .4rem .8rem;
  font-size: 1.2rem;
  text-decoration: none;
  display: inline-block;
}

.cflex__show-all--dark, .cflex__show-random--dark {
  color: #f1f3f5;
  background-color: #495057;
}

.cflex__bad-request--dark, .cflex__not-found--dark {
  color: #e9ecef;
}

.focus {
  z-index: 3;
  transition: all .3s;
}

.error-message {
  font-size: 1.6rem;
}

.focus, .popup {
  backdrop-filter: blur(4px);
  z-index: 10;
  background-color: #0000004d;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
}

.hidden {
  visibility: hidden !important;
  opacity: 0 !important;
}

.disabled {
  display: none !important;
}

.country {
  color: #495057;
  cursor: pointer;
  background-color: #fff;
  border-radius: 3px;
  gap: 1rem;
  height: 3.6rem;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  overflow: hidden;
  box-shadow: 0 2rem 6rem #0000001a;
}

.country--dark {
  color: #e9ecef;
  background-color: #495057;
}

.country:hover {
  transform: translateY(-3px);
}

.country__flag, .country__flag svg {
  background-position: center;
  background-size: cover;
  border-radius: 3px;
  width: 4.4rem;
  margin: .4rem;
}

.country__name {
  align-self: center;
  font-size: 1.6rem;
}

.country-tool {
  color: #495057;
  cursor: pointer;
  background-color: #fff;
  border-radius: 9999px;
  grid-column: span 3;
  justify-content: center;
  gap: 1rem;
  height: 3.6rem;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  overflow: hidden;
  box-shadow: 0 2rem 6rem #0000001a;
}

.country-tool--dark {
  color: #e9ecef;
  background-color: #495057;
}

.country-tool--random {
  grid-column: 15 / span 2;
}

.country-tool--saved {
  grid-column: 17 / span 2;
}

.country-tool--search-by {
  grid-column: 1 / span 3;
}

.country-tool--sort {
  grid-column: 4 / span 3;
}

@media (width <= 1359px) {
  .country-tool--random {
    grid-column: 14 / span 2;
  }

  .country-tool--saved {
    grid-column: 16 / span 3;
  }

  .country-tool--search-by {
    grid-column: 1 / span 4;
  }

  .country-tool--sort {
    grid-column: 5 / span 4;
  }
}

@media (width <= 1220px) {
  .country-tool--random {
    grid-column: 13 / span 3;
  }

  .country-tool--saved {
    grid-column: 16 / span 3;
  }
}

@media (width <= 900px) {
  .country-tool--random {
    grid-column: 12 / span 3;
  }

  .country-tool--saved {
    grid-column: 15 / span 4;
  }

  .country-tool--search-by {
    grid-column: 1 / span 5;
  }

  .country-tool--sort {
    grid-column: 6 / span 4;
  }
}

@media (width <= 600px) {
  .country-tool--search-by {
    grid-column: 1 / span 9;
  }

  .country-tool--random {
    grid-area: 2 / 1 / auto / span 9;
  }

  .country-tool--saved {
    grid-area: 2 / 10 / auto / span 9;
  }

  .country-tool--sort {
    grid-column: 10 / span 9;
  }

  .country-tool--show-all {
    grid-area: 3 / 1 / auto / span 18;
  }
}

.country-tool--search-by {
  grid-row: span 1;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding-left: 1.6rem;
  display: flex;
}

.country-tool--search-by select {
  color: #495057;
  cursor: pointer;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 9999px;
  width: 100%;
  padding: .8rem 1.6rem;
  font-size: 1.6rem;
  transition: all .3s;
}

.country-tool--search-by select:hover {
  border-color: #adb5bd;
}

.country-tool--search-by select:focus {
  border-color: #868e96;
}

.country-tool--search-by select option {
  color: #495057;
}

.country-tool--sort {
  grid-row: span 1;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding-left: 1.6rem;
  display: flex;
}

.country-tool--sort select {
  color: #495057;
  cursor: pointer;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 9999px;
  width: 100%;
  padding: .8rem 1.6rem;
  font-size: 1.6rem;
  transition: all .3s;
}

.country-tool--sort select:hover {
  border-color: #adb5bd;
}

.country-tool--sort select:focus {
  border-color: #868e96;
}

.country-tool--sort select option {
  color: #495057;
}

.country-tool:hover {
  transform: scale(1.1);
}

.country-tool__icon {
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  display: flex;
}

.country-tool__name {
  align-self: center;
  font-size: 1.6rem;
}

.country-tool__switch {
  pointer-events: none;
  align-self: center;
  width: 30px;
  height: 17px;
  display: inline-block;
  position: relative;
}

.country-tool__switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.country-tool__slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.country-tool__slider:before {
  content: "";
  background-color: #fff;
  width: 13px;
  height: 13px;
  transition: all .4s;
  position: absolute;
  bottom: 2px;
  left: 2px;
}

input:checked + .country-tool__slider {
  background-color: #2196f3;
}

input:focus + .country-tool__slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .country-tool__slider:before {
  transform: translateX(13px);
}

.country-tool__slider.round {
  border-radius: 17px;
}

.country-tool__slider.round:before {
  border-radius: 50%;
}

.details {
  background-color: #fff;
  border-radius: 3px;
  flex-direction: column;
  gap: .8rem;
  width: 75vw;
  height: 90vh;
  padding: 1.6rem 2.4rem;
  transition: all .5s;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (width <= 900px) {
  .details {
    width: 82.5vw;
  }
}

@media only screen and (width <= 600px) {
  .details {
    width: 90vw;
  }
}

.details--dark {
  color: #fff;
  background-color: #495057;
}

.details__buttonbar {
  justify-content: flex-end;
  gap: .4rem;
  display: flex;
}

.details__map-button {
  cursor: pointer;
  z-index: 100;
  border-radius: 1000px;
  padding: .8rem 2rem;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  bottom: 1.6rem;
  right: 1.6rem;
  box-shadow: 0 2rem 6rem #0000004d;
}

.details__map-button:hover {
  transition: all .3s;
  transform: translateY(-.8rem);
}

.details__map-button:active {
  transition: all .3s;
  transform: translateY(-.4rem);
}

.details__map-button--googlemaps {
  color: #dfd;
  background-color: green;
}

.details__map-button--openstreetmap {
  color: #ccc;
  background-color: gray;
}

.details__header {
  align-items: center;
  gap: 2.4rem;
  display: flex;
}

.details__title {
  letter-spacing: -.1rem;
  color: #000;
  flex: 1;
  align-items: center;
  gap: .8rem;
  font-family: Gilroy, Plus Jakarta Sans, Open Sans, sans-serif;
  display: inline-flex;
}

.details__title--dark {
  color: #fff;
}

.details__title p {
  font-size: 1.6rem;
  font-weight: 600;
}

.details__title-frame {
  background-color: #ccc;
  border-radius: 9999px;
  align-items: center;
  gap: 1.2rem;
  padding: .4rem .6rem;
  display: inline-flex;
}

.details__title-button {
  cursor: pointer;
  border-radius: 9999px;
  padding: .5rem 1rem;
  display: inline-block;
}

.details__title-button--details {
  background-color: #eee;
}

.details__save-icon {
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
}

.details__save-icon:hover {
  transition: all .3s;
  transform: scale(1.2);
}

.details__exit {
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
}

.details__exit:hover {
  transition: all .3s;
  transform: scale(1.2);
}

.details__content {
  flex: 1;
  grid-template-rows: 1.2fr 1.8fr;
  grid-template-columns: .8fr 3.2fr;
  column-gap: 2rem;
  min-width: 0;
  min-height: 0;
  display: grid;
}

@media only screen and (width <= 600px) {
  .details__content {
    grid-template-rows: 1fr 3fr;
    grid-template-columns: 1.8fr 1.2fr;
  }
}

.details__content img {
  border-radius: 3px;
  max-width: 100%;
  max-height: 100%;
  transition: transform .3s;
}

@media only screen and (width <= 600px) {
  .details__content img {
    max-width: 100%;
    max-height: 100%;
  }
}

.details__content img:hover {
  transform: scale(1.5);
}

.details__flag {
  align-self: center;
}

.details__flag img {
  border: 1px solid #f1f3f5;
}

.details__arms {
  order: 3;
  align-self: center;
}

@media only screen and (width <= 600px) {
  .details__arms {
    order: 2;
  }
}

.details__list {
  order: 2;
  grid-row: span 2;
  padding: 1.8rem 0;
  display: grid;
}

@media only screen and (width <= 600px) {
  .details__list {
    order: 3;
    grid-column: span 2;
  }
}

.details__list-item {
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
  display: flex;
}

.details__list-item:nth-child(2n) {
  background-color: #f1f3f5;
}

.details__list-item--dark:nth-child(2n) {
  background-color: #343a40;
}

.details__list-item p {
  font-weight: 700;
}

.details__list-item span {
  font-weight: 400;
}

.map {
  grid-area: 1 / 1 / -1 / -1;
  width: 100%;
  height: 100%;
}

.switch {
  width: 60px;
  height: 34px;
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  background-color: #fff;
  width: 26px;
  height: 26px;
  transition: all .4s;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.footer {
  text-align: center;
  color: #343a40;
  background-color: #e9ecef;
}

.footer--dark {
  background-color: #495057;
}

.footer__text {
  color: #343a40;
  margin: 0 1rem;
  font-size: 1.2rem;
  display: inline-block;
}

.footer__text--dark {
  color: #f1f3f5;
}

.footer__link {
  color: #00f;
  text-decoration: underline;
}

.footer__link--dark {
  color: #add8e6;
}

a.footer__text {
  transition: all .3s;
}

a.footer__text:link, a.footer__text:visited {
  font-weight: inherit;
}

a.footer__text:hover, a.footer__text:active {
  font-weight: 700;
  text-decoration: none;
}

.hero {
  background-color: #e9ecef;
}

.hero--dark {
  background-color: #495057;
}

.hero__text-box {
  letter-spacing: -.7px;
  font-family: Gilroy, Plus Jakarta Sans, Open Sans, sans-serif;
  font-size: 2.4rem;
  position: absolute;
  top: 25vh;
  transform: translateY(-50%);
}

.hero__title {
  color: #343a40;
  font-weight: 900;
}

.hero__title--dark {
  color: #f1f3f5;
}

.hero__description {
  color: #868e96;
  font-weight: 500;
}

.hero__description--dark {
  color: #dee2e6;
}

.search {
  background-color: #fff;
  border-radius: 1000px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 2rem 6rem #0000001a;
}

.search--dark {
  background-color: #343a40;
}

.search__input {
  background-color: inherit;
  color: #212529;
  border: none;
  flex: 1;
  height: 100%;
  padding: 0 1.6rem;
  font-family: Gilroy, Plus Jakarta Sans, Open Sans, sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
}

.search__input--dark {
  color: #f8f9fa;
}

.search__input:focus {
  outline: none;
}

.search__input:placeholder-shown {
  font-size: 2rem;
}

@media (width <= 600px) {
  .search__input:placeholder-shown {
    font-size: 1.6rem;
  }
}

.search__button {
  background-color: inherit;
  cursor: pointer;
  border: none;
  width: 7rem;
  height: 100%;
  position: relative;
}

.search__icon {
  color: #343a40;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.search__icon--dark {
  color: #f1f3f5;
}

.search__icon:hover {
  transition: transform .3s;
  transform: scale(1.3)translate(-50%, -50%);
}

.loader-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
  display: flex;
}

.loader-container--details {
  margin-top: 40vh;
  transform: translateY(-50%);
}

.loader {
  border: 1.2rem solid #ced4da;
  border-top-color: #868e96;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  animation: 2s linear infinite spin;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.toolbar {
  padding: .8rem;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.toolbar > * {
  cursor: pointer;
  padding: .8rem;
}

.toolbar__icon {
  color: #495057;
}

.toolbar__icon--dark {
  color: #e9ecef;
}
/*# sourceMappingURL=index.002fa4f5.css.map */
