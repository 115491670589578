.toolbar {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.8rem;
  display: flex;

  & > * {
    cursor: pointer;
    padding: 0.8rem;
  }

  &__icon {
    color: $color-gray-7;

    &--dark {
      color: $color-gray-2;
    }
  }
}
